<template>
    <div class="row">
        <div class="col-md-9">
            <div class="card mb-5 mb-xl-10 rounded-0">
                    <div class="card-header">
                        <div class="card-title">
                            All Domains
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="d-flex justify-content-end">
                                <button class="btn btn-sm btn-active btn-primary fw-bolder px-4 me-1" @click="showModalDomain">Add Domain</button>
                            </div>
                        </div>
                        <div class="row mt-5 ms-0">
                        </div>
                        <div class="tab-content">
                            <div class="tab-pane fade show active">
                                <div class="table-responsive pe-7">
                                    <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                        <thead>
                                            <tr class="fw-bolder text-muted text-uppercase">
                                                <th class="min-w-150px">Name</th>
                                                <th class="min-w-150px">Domain</th>
                                                <th class="min-w-150px">Date</th>
                                                <th class="min-w-100px text-start">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="getDomainsData.length > 0">
                                            <tr id="all_entries_raw_"  v-for="(data, index) in getDomainsData" :key="index">
                                                <td>
                                                    <span class="text-gray-700">{{ data.name }}</span>
                                                </td>
                                                <td>
                                                    <span class="fw-boldest text-gray-700">{{ data.domain }}</span>
                                                </td>
                                                <td>
                                                    <span class="text-gray-700"> {{format_date(data.created_at)}}</span>
                                                </td>
                                                <td>
                                                    <div class="d-flex justify-content-start flex-shrink-0">
                                                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 view-entiries" @click="editDomain(data)" data-type="all_entries">
                                                            <span class="svg-icon svg-icon-3 cursor-pointer">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                    <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1 0 32c0 8.8 7.2 16 16 16l32 0zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z" fill="#a1a5b7"/></svg>
                                                            </span>
                                                        </a>
                                                        <a href="#" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm delete-entiries" @click="deleteDomain(data.id)" data-type="all_entries">
                                                            <span class="svg-icon svg-icon-3 cursor-pointer">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
                                                                    <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
                                                                    <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
                                                                </svg>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="4" class="text-center">No Data Found</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                          
                        </div>
                    </div>
            </div>
        </div>
    </div>
    <Model modaltitle="Add Domain" @close="closeDomainModal()" v-if="showDomainModal"  modelSize="modal-lg">
    <template #body>
      <form method="post" @submit.prevent="addDomain()">
        <div class="modal-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-5 fv-row fv-plugins-icon-container">
                        <label for="exampleFormControlInput1" class="required form-label">Domain Name</label>
                        <input type="text" class="form-control form-control-solid" :class="{ 'is-invalid custom-border': v$.domain.name.$errors.length }" v-model="v$.domain.name.$model" placeholder="Enter Domain Name" />
                        <span class="invalid-feedback" role="alert" v-for="(error, index) of v$.domain.name.$errors" :key="index">
                             <strong>Domain Name is required</strong>
                        </span>
                    </div>
                    <div class="mb-5 fv-row fv-plugins-icon-container">
                        <label for="domainurl" class="required form-label">Domain Url</label>
                        <div id="prepended-field" role="group" class="input-group input-group-transparent destination-url-icon mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><strong class="">https://</strong></div>
                            </div>
                            <input id="domain-url" type="text" class="form-control form-control-solid" :class="{ 'is-invalid custom-border': v$.domain.domain.$errors.length }" v-model="v$.domain.domain.$model" placeholder="Enter Domain">
                            <span class="invalid-feedback" role="alert" v-for="(error, index) of v$.domain.domain.$errors" :key="index">
                             <strong>Domain Url is required</strong>
                            </span>
                        </div>
                    </div>
                    <div class="mb-5 fv-row fv-plugins-icon-container">
                        <label for="" class="required form-label">Root Redirect Url</label>
                        <input type="text" class="form-control form-control-solid" v-model="domain.redirect_url" placeholder="Enter Redirect URL" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="w-100">
                    <h5 class="">Domain Instruction</h5>
                        <div class="video-box position-relative" style="padding-bottom: 56.25%;">
                            <iframe  src="https://player.vimeo.com/video/743252784?h=c8e9c16041&title=0&byline=0&portrait=0" class="custom-iframe" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
        <div class="modal-footer justify-content-between">
            <div class="d-flex">
                <div>
                    <h6 class="custom-record-title">Create A Record With</h6>
                    <p class="mb-0"><i class="far fa-file-alt text-warning mr-2"></i> <span class="tw-font-medium">cname.quizforma.com</span></p>
                    </div>
                <div class="btn ml-3 px-4 btn-outline custom-copy-button" @click="copyCname">Copy</div>
            </div>
            <div class="d-flex">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal" @click="closeDomainModal()">
                  Close
                </button>
                <button
                    type="submit"
                    class="btn btn-bg-primary text-white w-100 ml-3"
                    :disabled="loader"
                >
                    <span v-if="!loader"> Save</span>
                    <span
                        class="indicator-progress d-block"
                        v-else
                    >
                        Please wait...
                        <span
                            class="spinner-border spinner-border-sm align-middle ms-2"
                        ></span>
                    </span>
                </button>
            </div>
        </div>
      </form>
    </template>
  </Model>
</template>
<script>
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import useVuelidate from "@vuelidate/core";
import Model from "@/components/custom-components/model.vue";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import moment from "moment";
import { required } from "@vuelidate/validators";

export default defineComponent({
  name: "Domain",
  components: {
    Model
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Domain");
    });
    const toast = useToast();
    return { toast, v$: useVuelidate() };
  },
  data() {
    return {
        showDomainModal:false,
        domain:{
            id:null,
            name:null,
            domain:null,
            redirect_url:null
        },
        loader:false
    }
  },
  validations() {
    return {
        domain: {
            name: { required },
            domain: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
        getDomainsData:'Domain/getDomainsData',
    }),
    format_date() {
      return (value) => {
        return moment(value).format("MM/DD/YYYY HH:mm:ss");
      };
    },
  },
  methods: {
    ...mapActions({
        fetchDomainList:'Domain/fetchDomainList',
        addDomainDb:'Domain/addDomain',
        deleteDomainDb:'Domain/deleteDomain',
    }),
    addDomain(){
        this.v$.domain.$touch();
        if (this.v$.domain.$invalid) {
            return
        }
        this.loader=true
        this.addDomainDb(this.domain).then(
        (res) => {
          this.loader=false
          if (res.data.status) {
            this.showDomainModal = false;
            this.toast.success('Domian Saved Successfully');
            this.fetchDomainList()
          } else {
            if (!res.data.status) {
              this.toast.error(res.data.message);
            }
          }
        }
      ).catch((error) => {
        this.loader=false
        this.toast.error(error.message);
      });
    },
    closeDomainModal(){
        this.v$.domain.$reset();
        this.showDomainModal=false
    },
    showModalDomain(){
        this.v$.domain.$reset();
        this.domain={
            id:null,
            name:null,
            domain:null,
            redirect_url:null
        }
        this.showDomainModal=true
    },
    copyCname(){
      document.addEventListener(
          "copy",
          function(e) {
            e.clipboardData.setData("text/plain", 'cname.quizforma.com');
            e.preventDefault();
          },
          true
      );
      document.execCommand("copy");
      this.toast.success("Copied Successfully", "Copied!");
    },
    editDomain(data){
        this.domain={
            id:data.id,
            name:data.name,
            domain:data.domain,
            redirect_url:data.redirect_url
        },
        this.showDomainModal=true
    },
    deleteDomain(id){
        this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteDomainDb({id:id})
              .then((response) => {
                if (response.data.status == true) {
                    this.toast.success('Domian Deleted!');
                    this.fetchDomainList()
                }
              })
              .catch((error) => {
                this.toast.error(error.response.data.message);
              });
          }
        });
    }
  },
  async mounted() {
       await this.fetchDomainList()
  },
});
</script>
<style scoped>
.input-group-text{
    line-height: 1.7;
    background-color: #dbdbdb;
    border: none !important;
    border-radius: 0.475rem 0rem 0rem 0.475rem;
}
.video-box, .custom-iframe {
    margin: auto;
    width: 100%;
}
.custom-iframe {
    border: none;
    box-shadow: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 2;
}
.custom-copy-button{
  color: #474d84;
  border-color: #474d84;
}
.custom-record-title{
  color:#E49400 !important;
  font-weight: 600;
}
</style>